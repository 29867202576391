export const MenuItems = [
    {
      title: 'Baords',
      path: './#boards',
      cName: 'dropdown-link'
    },
    {
      title: 'Classes',
      path: './#classes',
      cName: 'dropdown-link'
    },
    {
      title: 'Extra-Curricular',
      path: './#extra',
      cName: 'dropdown-link'
    }
  ];
  