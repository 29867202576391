import React, {Component} from 'react';
import './Aboutus.css';
// import About from '../Aboutus/Aboutus';
 
// import {AboutusData} from '../Aboutus/AboutusData';
// import '../Aboutus/Aboutus.css';

// import { Fade } from "react-awesome-reveal";

class Aboutusmain extends Component{

  render(){
    return (
      <>
      <div >
      <div className="row">
      <h1 className="aboutusmain-h1 text-center">Our Story</h1>
      </div>

      <div className="row">
     <img className="aboutusbannerimg align-center" alt="MathSutra - Step In to Succeed" src="https://mathsutra.s3.ap-south-1.amazonaws.com/MATHsutra-aboutus-3.png"/>
      </div>

      

        <div className="container">
       
          <div className="row space">
          <h2 className="aboutus-h2">Vision & Mission </h2>
          
          <p className="aboutusmain-p">
            Over the last 5+ years, we have unlearnt and learnt right to be able to teach right. 
            Our Mission has never been clearer
            We exist to take the fear out of learning
            We exist to inspire the right way of learning
            When these two goals are achieved, it is but natural that results should follow.</p>
            </div>
            <p/>
            <div className="row space">
            <h2 className="aboutus-h2">Message from the Founder</h2>
          <p className="aboutusmain-p">Six Years ago, when I decided to pursue my passion of teaching mathematics,
             I sat down to think about the usefulness of my passion to the world. 
             Most times, the teaching process is cluttered and riddled with mechanical rules and
             has an inordinate focus on the end-result. The classical pedagogy is sometimes 
                so overwhelming up-front that it causes a fear-inspired mind-block.
             In short, you give up before you start .
             All the things that should eventually matter, including learning and knowledge, become deprioritized.</p> 
           
            <p/>

          
          <p className="aboutusmain-p"> Having been academically successful, and as a product of the classical rote-based Indian education system, 
          it's only when I started donning a teacher's hat did these problems expose themselves. 
          After having taught hundreds of students, I have never been more convinced that these are
           indeed the fundamental problems that we want to solve, and at the next level of scale.</p>
         </div>

           <div className="row space">
            <h2 className="aboutus-h2">Our Pedagogy</h2>
            <p/>
            <div className="row">
            <p className="aboutusmain-p space">
            
            
                <tr>* Classroom Sessions</tr>
                <tr>* Hybrid Setup for students who cannot attend class physically</tr>
                <tr>* Regular Assessments</tr>
                <tr>* Student's clinic</tr>
                <tr>* Parent Engagement</tr>
            </p>
            </div>
            </div>

         
          </div>
          </div>
          <p/>
          <p/>
          <section id="Team">
            <div className="row">
            <h1 className="aboutusmain-h1 text-center">Our Team</h1>
            </div>
            <div className="row">
            <img className="aboutusbannerimg align-center" alt="MathSutra by Swaroop" src="https://mathsutra.s3.ap-south-1.amazonaws.com/Teams/Swaroop.png"/>
            </div>
            <div className="row">
            <img className="aboutusbannerimg align-center" alt="MathSutra by Swaroop" src="https://mathsutra.s3.ap-south-1.amazonaws.com/Teams/Sagar.png"/>
            </div>
            <div className="row">
            <img className="aboutusbannerimg align-center" alt="MathSutra by Swaroop" src="https://mathsutra.s3.ap-south-1.amazonaws.com/Teams/Abhishek.png"/>
            </div>
            <div className="row">
            <img className="aboutusbannerimg align-center" alt="MathSutra by Swaroop" src="https://mathsutra.s3.ap-south-1.amazonaws.com/Teams/Arun.png"/>
            </div>
            </section>
          </>
        );
  }
    
    }


  
export default Aboutusmain;


// Vision & Mission 

// Over the last 5+ years, we have unlearnt and learnt right to be able to teach right. 

// Our Mission has never been clearer

// We exist to take the fear out of learning

// We exist to inspire the right way of learning

// When these two goals are achieved, it is but natural that results should follow.
// Message from the Founder

// Six Years ago, when I decided to pursue my passion of teaching mathematics,
// I sat down to think about the usefulness of my passion to the world. 
//Most times, the teaching process is cluttered and riddled with mechanical rules and
// has an inordinate focus on the end-result. The classical pedagogy is sometimes 
//so overwhelming up-front that it causes a fear-inspired mind-block.
// In short, you give up before you start .
//All the things that should eventually matter, including learning and knowledge, become deprioritized.

// Having been academically successful, and as a product of the classical rote-based Indian education system, it's only when I started donning a teacher's hat did these problems expose themselves. After having taught hundreds of students, I have never been more convinced that these are indeed the fundamental problems that we want to solve, and at the next level of scale.
// Our Pedagogy:Classroom Sessions,Hybrid Setup for students who cannot attend class physically,Regular Assessments, Student's clinic,Parent Engagement