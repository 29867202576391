import React, {Component} from 'react'
import CarouselSlider from 'react-carousel-slider';
import './Banner.css';
import ReactPlayer from 'react-player';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepOrange } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import banner1 from './B-1.png';
import banner2 from './B-2.png';
import banner3 from './B-3.png';
import banner4 from './B-4.png';


// const MyBanner = () => {
//   //const classes = useStyles();

//   return (
//     <section className="banner">
//      <div className="row container">
//       <img className="bannerimg" src="https://mathsutra.s3.ap-south-1.amazonaws.com/MATHsutra-1.png"/>    
//       </div>
//     </section>
//   );
// };



 
class MyBanner extends Component {
    render() {
    
        let data = [
            {
                imgSrc: banner1
                // imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Banner/1.png"
              
            },
            {
                imgSrc: banner2
                // imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Banner/2.png"
            },
          
            {
                imgSrc: banner3
                // imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Banner/3.png"
            },
            {
                imgSrc: banner4
                // imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Banner/4.png"
            }

        ];
        
        let manner = {
            autoSliding: {interval: "3s"},
            duration: "2s"
        };
        
        let itemsStyle = {
            //  margin: "0px 200px",
             padding: "0px"
            };
        return <CarouselSlider className="rcs_sliderSet rcs_sliderBox rcs_left rcs_right rcs_slideCon itemWrapper img"
                    slideItems = {data} 
                    manner={manner}
                     sliderBoxStyle ={{width:'100%', background:'F3F781'}} 
                     dotsSetting ={{placeOn:'bottom'}}
                    buttonSetting ={{placeOn: 'middle-outside'}}
                    itemsStyle ={{margin:'0px 40px'}}
                    // itemsStyle={itemsStyle}
                    />;
    }
}


export default MyBanner;
