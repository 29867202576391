import React from 'react'
/* import { Icon } from '@iconify/react'
import sendCircle from '@iconify-icons/mdi/send-circle' */
import axios from 'axios';
import { CountryDropdown } from 'react-country-region-selector';
import './Subscription.css'

class Subscription extends React.Component {

  state = {
    data:
    {
      name: '',    
      email:'' ,
      message:''    
    },
    successMessage:'',
    styleValue:'',
    checkMessage:''
  }

 formInputs = [
  { 
    id: 'name', 
    type: 'text', 
    label: 'Your name', 
    placeholder: 'John Doe',   
    datavalue:'name',
   },  
  {
    id: 'email',
    type: 'email',
    label: 'Email address',
    placeholder: 'you@example.com',
    datavalue:'email',
  }, 
  {
    id: 'message',
    type: 'textarea',
    label: 'Your message',
    placeholder: 'Let us know your interest',
    datavalue:'desc',
  },
]

  selectCountry (val) {

    this.setState({ country: val });
  }
handleChange = name => event =>
 { 
   this.setState({ [name]: event.target.value }); 
 //  document.getElementById("btnCheckFormData").style="display:none";    
   
 } 

  //  handleChange(event) {
  //   console.log(event.target.id);
  //   this.setState({ [event.target.id]: event.target.value }); 
  // }


handleSubmit = event => {
  event.preventDefault();
  const headers = {
    //  'Access-Control-Allow-Origin': 'http://localhost:3000',
      'Access-Control-Allow-Origin': 'https://themathsutra.com', 
      'Access-Control-Allow-Methods':'POST,GET,OPTIONS',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Methods,Access-Control-Allow-Headers,Access-Control-Allow-Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with',
      'X-Requested-With':'*'     
    }

  /* const enquiryData = {      
    name: this.state.name,
    phone: this.state.phone,
    email: this.state.email,
    desc: this.state.desc      
    }; */

    if((this.state.name===undefined) || (this.state.email===undefined))
    {
      this.checkMessage = "Please enter all values!!!";

      this.checkFormData();    
    }
    else
    {
    const enquiryConcatData= "<body><h3>Hello,</h3><div>Please find my details below<br/>Name:"+ this.state.name+" <br/> EMail:"+ this.state.email +"<br/> Details requested for:"+ this.state.desc + ". <br/> Thanks.</div></body></html>";
 
  
  axios.post(`https://ps7uanmdvf.execute-api.ap-south-1.amazonaws.com/Dev/contactemailresource`, { enquiryConcatData },{
      headers: headers
    })
    .then(res => {
      console.log(res);
      console.log(res.data);
      if(res.status===200)
      {      
       this.setStyle();
       this.clearForm();
      }
    },
    (error) => {
      this.successMessage='There was an error sending mail. Please try again.';
      console.log(error);
    })
    }    
   
}

checkFormData = () =>{
  if(this.checkMessage !=='')  {
    document.getElementById("btnCheckFormData").style="display:block"; 
  }
}

setStyle = () =>{  
  if(this.successMessage!=='')  {
    document.getElementById("btnSuccess").style="display:block"; 
    document.getElementById("btnCheckFormData").style="display:none"; 
  }
}
clearForm = () => {  
  document.getElementById("contactForm").reset();
  // document.getElementById("btnSuccess").style="display:none";
  document.getElementById("btnCheckFormData").style="display:none"; 
}

render() {
  return  (
   
   <React.Fragment>
  
  
   <form id="contactForm" className="form" onSubmit={this.handleSubmit}>
    <h2 className="form-h2">Mail Us to enquire about our courses or to book a free demo class</h2>

   {/* <input id="name" type="text" placeholder="Name" className="form-input"  onChange={this.handleChange}/>
    <input id="email" type="text" placeholder="Email" className="form-input"  onChange={this.handleChange}/>
     <input id="message" type="textarea" placeholder="Desc" className="form-textarea" onChange={this.handleChange}/> */}

     {this.formInputs.map(input => (
      <label key={input.label} id={input.id} className="form-label" >
        {input.label}

        {input.type === 'textarea' ? (
          <textarea className="form-textarea" placeholder={input.placeholder} onChange={this.handleChange('desc')} />
        ) : (
          <input
            className="form-input"
            type={input.type}
            placeholder={input.placeholder}
            onChange={this.handleChange(input.datavalue)}
          />
        )}
      </label>
    ))}
    {/* <CountryDropdown className="countrydropdown"
          value={this.state.country}
          onChange={(val) => this.selectCountry(val)} /> */}

    <button className="form-submit" type="submit">
      Submit
    </button>
    {/* <button type="button" className="form-submit" id="btnSuccess" onClick={this.clearForm} style={{display:"none"}}>Thanks for your Mail!!! We will get in touch with you.</button> */}
     <label type="label" className="label-submit" id="btnSuccess" style={{display:"none"}}>Thank you for your interest.</label>
     {/* <button type="button" className="form-submit" id="btnCheckFormData" style={{display:"none"}}>Please enter all values!!!.</button> */}
      <label type="label" className="label-submit" id="btnCheckFormData" style={{display:"none"}}>Please enter all values!!!.</label>
   </form>   
 </React.Fragment>
 
)
    }
      }
export default Subscription
