import React, {Component} from 'react';
//import {Card} from 'react-bootstrap';
// import "../../../../node_modules/bootstrap/dist/bootstrap.min.css";
import './ProductLayout.css'
import { Link } from 'react-router-dom';
import { Fade } from "react-awesome-reveal";

class ProductLayout extends Component {

    constructor(props)
    {
        super(props);
    }

    render(){
        return(
            <div className="prodcard">
                <div className ="overflow">
                    <img src={this.props.imgSrc} alt= {this.props.cardInfo} className="card-image-top"></img>
                </div>
                {/* <div className="prodcard-body text-dark">
                     
                </div> */}
            </div>
        );
    };


};

export default ProductLayout;