import React from 'react';
import '../../App.css';
import Banner from  '../Banner/MyBanner';
import Testimonials from './Testimonials';
import Subscription from '../Subscription/Subscription';
import Products from './Products';
import AboutUs from './AboutusPage';
import Courses from './Courses';
import Boards from './Boards';
// import Videos from './YoutubeEmbed';
import YouTube from 'react-youtube';
import msteam from './MS-Team.png';

import { BrowserRouter,Link, NavLink } from 'react-router-dom';

// import Visits from '../Pages/Classesdetails';
// import Amenitiesone from '../Pages/ExtraCourse';
// import Amenitiestwo from '../Pages/Amenities';
import ContactSection from '../contact-section/ContactSection'; 
// import Maps from '../contact-section/Maps';
// //import Testimonials from '../Testimonials/Testimonials';
// import Boards from './Boards';
// import { Icon, InlineIcon } from '@iconify/react';
// import handPointRight from '@iconify-icons/fa-regular/hand-point-right';
import { HashLink } from 'react-router-hash-link';

export default function Home() {

  const onReady = (e) => {
            console.log(e.target);
        };

        const opts = {
          height: '290',
          width: '100%',
          left:'10px'
        }
  return (
     
    <React.Fragment>
      <section>
      <div>
      <Banner/>
      </div>  
      <p/>
      <p/>
     <div className="row whyus">
      <h2 className="why-h2">Welcome Students & Parents !</h2>
      <p/>
     {/* <AboutUs/> */}

     <section id="aboutus">
      <div className="container">
    <div className="row">        
        <div className="col-lg-6">
            <img className="aboutimage" alt="MathSutra - Step In to Succeed" src="https://mathsutra.s3.ap-south-1.amazonaws.com/about-center-2.png"/>
        </div>
        <div className="col-lg-6 ">         
          <p className="aboutus-p">Welcome to MathSutra Learning Academy, a one stop solution for all your childs needs for Maths & Science.</p>
          <p className="aboutus-p"> Our team of experienced teachers will guide your child through the subjects helping them to understand the concepts with utmost clarity.</p>
          <p className="aboutus-p"> Step in to our academy to succceed!!</p>
          
        </div>
   </div>
   <p/>
   <div className="row">
      <NavLink to='/aboutus' className='readmoreLink'>
          Read More
         </NavLink>
    </div>
</div>
</section>
      </div>
     {/* <ContactSection/> */}
     </section>
     <p/>
      <section>
      <div>
      <h2 className="why-h2">Why Choose Us</h2>
      <p/>
      </div>
      <Products/>
      </section>
      <p/>
      <section>
      <div>
      <h2 className="why-h2">What We Offer</h2>
      <p/>
      </div>
      <Courses/>
        </section>
     <section>
      <p/>
      <h2 className="why-h2">Boards We Handle</h2>
      <div className="row">
      <Boards/>
        </div>
      </section>
    <p/>
    <h2 className="why-h2">Satisfied Parents</h2>
      <div className="row">
        <Testimonials/>
        </div>
        <p/>
      
        <div>
    <h2 className="why-h2 banner-top">Our Team</h2>
      <div className="row">
      <img className="team" src= {msteam} alt="MathSutra - Step In to Succeed"/>
        </div>
        <div className="row">
      {/* <NavLink to='/aboutus#Team' className='readmoreLink'>
          Meet our Team
         </NavLink> */}
         <HashLink smooth to='/aboutus/#Team' className='readmoreLink'> Meet our Team </HashLink>
    </div>
    {/* "https://mathsutra.s3.ap-south-1.amazonaws.com/MS-Team.png" */}
    <h2 className="why-h2">Video Sessions</h2>
    <div className="row">
     <div className="col-md-4">
       <YouTube videoId="P8a4SOS-eww" onReady={onReady} opts={opts} />
      </div>
      <div className="col-md-4">
       <YouTube videoId="_zTr8YqR3mU" onReady={onReady} opts={opts} />
      </div>
      <div className="col-md-4">
       <YouTube videoId="bTHYNqf-Lo4" onReady={onReady} opts={opts} />
      </div>
      </div>
      <div className="row">
      <NavLink to='https://www.youtube.com/@mathsutrabyswaroop1405' className='readmoreLink' target='_blank'>
          View More
         </NavLink>
    </div>
        </div>
        <p/>       
    </React.Fragment>
   
    
 
  );
}

  {/* <Videos embedId="P8a4SOS-eww" /> */}