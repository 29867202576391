export const AboutusData = [
    {
      courseId:1,  
      cardTitle: 'Welcome Students & Parents !',
      imgSrc: 'https://mathsutra.s3.ap-south-1.amazonaws.com/about-center.png',
      cardInfo: 'Welcome to MathSutra Learning Academy, a one stop solution for all your childs needs for Maths & Science. Our team of experienced teachers will guide your child through the subjects helping them to understand the concepts with utmost clarity. Step in to our academy to succceed!!',

      readMore:'Read More',
      routeValue:'/CbseCourseDetails'
    }
    
  ];
  