import Photoalbum from 'react-photo-album';
const photos=[
    { src:"https://mathsutra.s3.ap-south-1.amazonaws.com/Gallery/1.JPG", width: 800, height:600 },
    {src:"https://mathsutra.s3.ap-south-1.amazonaws.com/Gallery/2.JPG", width: 800, height:500},
    {src:"https://mathsutra.s3.ap-south-1.amazonaws.com/Gallery/3.JPG", width: 800, height:500},
    {src:"https://mathsutra.s3.ap-south-1.amazonaws.com/Gallery/4.JPG", width: 700, height:500},
    {src:"https://mathsutra.s3.ap-south-1.amazonaws.com/Gallery/5.JPG", width: 700, height:500},
    {src:"https://mathsutra.s3.ap-south-1.amazonaws.com/Gallery/6.JPG", width: 700, height:500},


];

export default function Gallery(){
    return (
        <>
        <h2 className="why-h2">Gallery</h2>
        <div className="gallery">
    <Photoalbum layout="rows" photos={photos}/>
    </div>
    </>
    );

} 