import React from 'react'

import Form from './Form'
//import Form from './EnquiryForm';
import Subscription from '../Subscription/Subscription';
import Info from './Info'
import MapsSection from './Maps';

// import './contact-section.css'

const ContactSection = () => (
 <>
    <div className="contact-section">
      {/* <Subscription /> */}
      <Info />     
    </div>
    <div>
    <MapsSection/>
    </div>
 </>
)

export default ContactSection
