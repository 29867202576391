import React, {Component} from 'react'
import CarouselSlider from 'react-carousel-slider';
import './Pages.css';
import ReactPlayer from 'react-player';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepOrange } from '@mui/material/colors';
import { Link } from 'react-router-dom';



class Testimonials extends Component {
    render() {
    
        let data = [
            {
                // des: "1",           
                imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Reviews/GR-1.png"
              
            },
            {
                // des: "1",           
                imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Reviews/GR-2.png"
              
            },
            {
              //  des: "2",
                imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Reviews/GR-3.png"
            },
          
            {
              //  des: "2",
                imgSrc: "https://mathsutra.s3.ap-south-1.amazonaws.com/Reviews/GR-4.png"
            }

        ];
        
        let manner = {
            autoSliding: {interval: "6s"},
            duration: "5s"
        };
        
        let itemsStyle = {
            //  margin: "0px 200px",
             padding: "0px"
            };
        return <CarouselSlider className="rcs_sliderSet rcs_sliderBox rcst_left rcs_right rcs_slideCon itemWrapper img"
                    slideItems = {data} 
                    manner={manner}
                     sliderBoxStyle ={{width:'100%', background:'F3F781'}} 
                     dotsSetting ={{placeOn:'bottom'}}
                    buttonSetting ={{placeOn: 'bottom-beneath'}}
                    itemsStyle ={{margin:'0px 40px'}}
                    // itemsStyle={itemsStyle}
                    />;
    }
}


export default Testimonials;
