import React, {Component} from 'react';
//import {Card} from 'react-bootstrap';
// import "../../../../node_modules/bootstrap/dist/bootstrap.min.css";
import './CourseLayout.css'
import { Link } from 'react-router-dom';

class CourseLayout extends Component {

    constructor(props)
    {
        super(props);
    }

    render(){
        return(
            <div className="prodcard">
                <div className ="overflow">
                    <img src={this.props.imgSrc} alt={this.props.cardInfo} className="card-image-top"></img>
                </div>
                
            </div>
        );
    };


};

export default CourseLayout;