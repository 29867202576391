// import { Component } from '@aws-amplify/ui-components/dist/types/stencil-public-runtime';
import React, {Component} from 'react';
import './Maps.css';

class Maps extends Component{
    render(){
        return (
         <div className="mapbg">
       <div className="mapinfo">
       <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d124457.50302250178!2d77.51203345795963!3d12.888616091507066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bae1563cb1b1dd9%3A0x8e4630de90079395!2s15%2C%20Padma%20Complex%2C%20opp.%20RBI%20Layout%2C%20RBI%20Layout%2C%20JP%20Nagar%207th%20Phase%2C%20WGHBCS%20Layout%2C%20Bengaluru%2C%20Karnataka%20560078!3m2!1d12.888625399999999!2d77.58207399999999!5e0!3m2!1sen!2sin!4v1677130433052!5m2!1sen!2sin" 
       width="600" height="450" background-color= "#e1653c" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>" 
      
       </div>
       </div>
    );
    }

}


export default Maps;

