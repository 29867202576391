import React, {Component} from 'react';
import About from '../Aboutus/Aboutus';
 
import {AboutusData} from '../Aboutus/AboutusData';
import '../Aboutus/Aboutus.css';

// import { Fade } from "react-awesome-reveal";

class AboutusPage extends Component{

    constructor()
    {
        super();
        this.courseList = this.courseList.bind(this);        
    }

    courseList =() => {
         const list = AboutusData.map((d) =>
      
                <div className="col-lg-6 cardposition" key={d.courseId}>                     
                     <About imgSrc={d.imgSrc} 
                                   cardInfo={d.cardInfo}  
                                   cardTitle={d.cardTitle}   
                                            
                     />                    
                    </div>                
                
         );     
        return (list);
    }
    render(){
        return(
          <>
            <div className="container-fluid d-flex justify-content-center">              
                <div className="row">    
               {this.courseList()}
              
             </div>
             
            </div>
            </>
        );
    }
}

  
export default AboutusPage;