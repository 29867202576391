import concept from './concept.png';
import hybrid from './hybrid.png';
import workshop from './workshop.png';
import tests from './tests.png';
import parent from './parent.png';
import passionate from './passionate.png';

export const ProductData = [
  {
    courseId:1,  
    cardTitle: '',
    imgSrc: concept,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/concept.png',
    cardInfo: 'Maths, Physics, Chemistry coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:2,  
    cardTitle: '',
    imgSrc: hybrid,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/hybrid.png',
    cardInfo: 'Maths, Physics, Chemistry coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:3,  
    cardTitle: '',
    imgSrc: workshop,
    //  'https://mathsutra.s3.ap-south-1.amazonaws.com/workshop.png',
    cardInfo: 'Maths, Physics, Chemistry coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:4,  
    cardTitle: '',
    imgSrc: tests,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/tests.png',
    cardInfo: 'Maths, Physics, Chemistry coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:5,  
    cardTitle: '',
    imgSrc: passionate,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/passionate.png',
    cardInfo: 'Maths, Physics, Chemistry coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'

  },
  {
    courseId:6,  
    cardTitle: '',
    imgSrc:parent,
    //  'https://mathsutra.s3.ap-south-1.amazonaws.com/parent.png',
    cardInfo: 'Maths, Physics, Chemistry coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  }
  ];
  