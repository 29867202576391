import React, {Component} from 'react';
import BoardsLayout from '../Boards/BoardsLayout.jsx';
 
import {BoardsData} from '../Boards/BoardsData';
import '../Boards/BoardsLayout.css';

import { Fade } from "react-awesome-reveal";

class Boards extends Component{

    constructor()
    {
        super();
        this.courseList = this.courseList.bind(this);        
    }

    courseList =() => {
         const list = BoardsData.map((d) =>
         <Fade direction="left" key={d.courseId} className="fadeprod">
                <div className="col-md-4" key={d.courseId}>                     
                     <BoardsLayout imgSrc={d.imgSrc} 
                                   cardInfo={d.cardInfo}                 
                     />                    
                    </div>
                    </Fade>

               
                
         );     
        return (list);
    }
    render(){
        return(
          
            <div className="container-fluid d-flex justify-content-center">              
                <div className="row">    
               {this.courseList()}
             </div>
            </div>
        );
    }
}

  
export default Boards;