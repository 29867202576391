import Boardstate from './Board-state.png';
import BoardICSE from './Board-ICSE.png';
import BoardsCBSE from './Boards-CBSE.png';

export const BoardsData = [
  {
    courseId:1,  
    cardTitle: '',
    imgSrc: BoardsCBSE,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/Boards-CBSE.png',
    cardInfo: 'Maths, Physics, Chemistry and CET coaching for CBSE in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:2,  
    cardTitle: '',
    imgSrc: BoardICSE,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/Board-ICSE.png',
    cardInfo: 'Maths, Physics, Chemistry and CET coaching for ICSE in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:3,  
    cardTitle: '',
    imgSrc: Boardstate,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/Board-state.png',
    cardInfo: 'Maths, Physics, Chemistry and CET coaching for Karnataka State Board in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  }
  ];
  