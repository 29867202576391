import React, {Component} from 'react';
import './Pages.css';
// import About from '../Aboutus/Aboutus';
 
// import {AboutusData} from '../Aboutus/AboutusData';
// import '../Aboutus/Aboutus.css';

// import { Fade } from "react-awesome-reveal";

class Privacypolicy extends Component{

  render(){
    return (
      <>
      <div className="row">
      <h2 className="why-h2">Privacy Policy</h2>
      </div>

        <div className="container">
       
          <div className="row policy">
          {/* <div className="col-lg-4">  */}
          
          <p className="aboutus-p">This Privacy Policy applies to the https://www.themathsutra.com
          https://www.themathsutra.com recognises the importance of maintaining your privacy. We value your privacy and appreciate your
          trust in us. This Policy describes how we treat user information we collect on https://www.themathsutra.com and other
          offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers.
          By visiting and/or using our website, you agree to this Privacy Policy.</p>
          <p className="aboutus-p">Information we collect</p>
          <p className="aboutus-p">Contact information.​We might collect your name, email, mobile number, phone number, street, city, state, pincode,
          country and ip address.</p>         
          <p className="aboutus-p">Other information.​If you use our website, we may collect information about your IP address and the browser you're
          using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site
          you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the
          operating system your computer or device is running.</p>
          <p className="aboutus-p">We collect information in different ways.</p>

          <p className="aboutus-p">We collect information from you passively.​We use tracking tools like Google Analytics, Google Webmaster,
          browser cookies and web beacons for collecting information about your usage of our website.
          We get information about you from third parties.​For example, if you use an integrated social media feature on
          our websites. The third­party social media site will give us certain information about you. This could include your
          name and email address</p>
          <p className="aboutus-p"> Use of your personal information
          We use information to contact you: ​We might use the information you provide to contact you for confirmation of a
          purchase on our website or for other promotional purposes.
          We use information to respond to your requests or questions.​We might use your information to confirm your
          registration for an event or contest.</p>

          <p className="aboutus-p">We use information to look at site trends and customer interests.​We may use your information to make our
          website and products better. We may combine information we get from you with information about you we get from
          third parties.
          We use information for security purposes.​We may use information to protect our company, our customers, or our
          websites.
          We use information for marketing purposes.​We might send you information about special promotions or offers.
          We use information as otherwise permitted by law.
          </p>
          <p className="aboutus-p">Sharing of information with third ­parties
           </p>
           <p/>
          <p className="aboutus-p-bold">We do not share information to any third parties</p>
          <p/>
          {/* </div> */}
          </div>
          </div>
          </>
        );
  }
    
    }


  
export default Privacypolicy;