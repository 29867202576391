import React from 'react'
import { Icon, InlineIcon } from '@iconify/react'

import locationIcon from '@iconify-icons/mdi/map-marker-radius-outline'
import phoneIcon from '@iconify-icons/mdi/phone-outline'
import emailIcon from '@iconify-icons/mdi/email-multiple-outline'

import facebookIcon from '@iconify-icons/mdi/facebook'
// import linkedinIcon from '@iconify-icons/mdi/linkedin'
//import twitterIcon from '@iconify-icons/mdi/twitter'

import whatsappIcon from '@iconify-icons/mdi/whatsapp';
import instagramIcon from '@iconify-icons/mdi/instagram';
import youtubeIcon from '@iconify-icons/mdi/youtube';


import './info.css'

const contactDetails = [
  {
    value: '1403, 1st B Cross, 18th Main Rd, 2nd Phase, J. P. Nagar, Bengaluru, Karnataka 560078',
    icon: locationIcon,
  },
  {
    value: '15, Padma Complex, opp. RBI Layout, RBI Layout, JP Nagar 7th Phase, WGHBCS Layout, Bengaluru, Karnataka 560078',
    icon: locationIcon,
  },
  
  { value: '+91 99168 97271', icon: phoneIcon },
  { value: 'contactus@themathsutra.com', icon: emailIcon },
]

const socialDetails=[
  {
    value:"https://www.facebook.com/mathsutrabyswaroop/",icon: facebookIcon},
  { value:"https://www.instagram.com/themathsutra/", icon: instagramIcon },
  { value: "https://www.youtube.com/@mathsutrabyswaroop1405", icon: youtubeIcon },
]
const renderContactDetails = () =>
  contactDetails.map(detail => (
    <p key={detail.value} className="info-detail">
      <InlineIcon icon={detail.icon} /> {detail.value}
    </p>
  ))

/* const renderIcons = () =>
  [facebookIcon, instagramIcon, whatsappIcon].map((icon, key) => (
    <Icon icon={icon} key={key} className="info-icon" onClick={openApp(key)} />
  )) */

  const renderIcons = () =>
  socialDetails.map(detail => (
    <div><a href={detail.value} target="_blank" rel="noreferrer">
    <Icon icon={detail.icon} key={detail.key} className="info-icon"></Icon></a>
     </div>
  ))

 /* const openApp = (key) = (e) =>
 {
   console.log(key);
   switch (key)
   {
     case 'facebookIcon':
        return window.open("https://www.facebook.com/sunedu.in", "_blank")
      case 'instagramIcon':
        return window.open("https://www.instagram.com/sunedufoundation/", "_blank")
      case 'whatsappIcon':
        return window.open("https //api.whatsapp.com/send phone=91 94445 64000", "_blank")   
   }
  
  } */
const Info = () => (
  <section className="info">
    <h2 className="info-h2">Contact Us</h2>

    <div className="info-details-container">{renderContactDetails()}</div>

    <div className="info-icons-container">{renderIcons()}</div>
  </section>
)

export default Info
