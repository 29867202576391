import React from 'react';

import { Icon } from '@iconify/react';


import phoneIcon from '@iconify-icons/mdi/phone-outline';

import facebookIcon from '@iconify-icons/mdi/facebook';
import whatsappIcon from '@iconify-icons/mdi/whatsapp';
import instagramIcon from '@iconify-icons/mdi/instagram';
import ContactSection from '../contact-section/ContactSection'; 
import './footer.css';

const Footer = () => (
<>
<ContactSection/>

  <div className="footer row">
       <div className="col-lg-6 text-left copyright">
       &copy;MathSutra &nbsp;
       {new Date().getFullYear()}
     </div> 
    <div className=" col-lg-6 text-right powered">
    <a href="www.podiumnow.in">PodiumNow Technologies</a>
  </div>
  </div>
 </>
)

export default Footer;


