import math from './math.png';
import physics from './physics.png';
import chemistry from './chemistry.png';
import CET from './CET.png';



export const CourseData = [
  {
    courseId:1,  
    cardTitle: '',
    imgSrc: math,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/math.png',
    cardInfo: 'Maths, Physics, Chemistry and CET coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:2,  
    cardTitle: '',
    imgSrc: physics,
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/physics.png',
    cardInfo: 'Maths, Physics, Chemistry and CET coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },
  {
    courseId:3,  
    cardTitle: '',
    imgSrc:chemistry,
    //  'https://mathsutra.s3.ap-south-1.amazonaws.com/chemistry.png',
    cardInfo: 'Maths, Physics, Chemistry and CET coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  },  
  {
    courseId:5,  
    cardTitle: '',
    imgSrc: CET, 
    // 'https://mathsutra.s3.ap-south-1.amazonaws.com/CET.png',
    cardInfo: 'Maths, Physics, Chemistry and CET coaching in JP Nagar, Jayanagar, Bannerghata, Kanakapura Road Bangalore'
  }
  ];
  