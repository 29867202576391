import React, {Component} from 'react';
import ProductLayout from '../Products/ProductLayout.jsx';
 
import {ProductData} from '../Products/ProductData';
import '../Products/ProductLayout.css';

import { Fade } from "react-awesome-reveal";

class Products extends Component{

    constructor()
    {
        super();
        this.courseList = this.courseList.bind(this);        
    }

    courseList =() => {
         const list = ProductData.map((d) =>
         <Fade direction="left" key={d.courseId} className="fadeprod">
                <div className="col-md-3" key={d.courseId}>                     
                     <ProductLayout imgSrc={d.imgSrc} 
                                   cardInfo={d.cardInfo}                 
                     />                    
                    </div></Fade>

               
                
         );     
        return (list);
    }
    render(){
        return(
          
            <div className="container-fluid d-flex justify-content-center">              
                <div className="row">    
               {this.courseList()}
             </div>
            </div>
        );
    }
}

  
export default Products;