import React, {Component} from 'react';
//import {Card} from 'react-bootstrap';
// import "../../../../node_modules/bootstrap/dist/bootstrap.min.css";
import './Aboutus.css'
import { Link } from 'react-router-dom';

class Aboutus extends Component {

    constructor(props)
    {
        super(props);
    }

    render(){
        
        return(
            <>
            <div className="container">
            <div className="row">
               <div className="col-md-6 col-xs-6 align-left">
                <div className ="overflow">
                    <img src={this.props.imgSrc} alt="Image1" className="card-image-top"></img>
                </div>
                </div>
                < div className="col-md-6 col-xs-6">
                {/* <div className="card-body text-dark"> */}
                      {/* <p className="card-text text-secondary"> */}
                        <p className="aboutus-p">
                        {this.props.cardInfo}
                        
                    </p> 
               
                {/* </div> */}
                </div>
            </div>
            </div>
            <div className="row">
            <button type="button" className="button">Read More</button>
            </div>
            </>
        );
    };


};

export default Aboutus;