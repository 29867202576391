
// import { Button } from './Button';
import React, { useState } from 'react';
import { BrowserRouter,Link, NavLink } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';


function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(true);
    } else {
      setDropdown(true);
     
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
  
    
      <nav className='navbar'>

         <NavLink to='/' className='navbar-logo' onClick={closeMobileMenu}>
         <img src="https://mathsutra.s3.ap-south-1.amazonaws.com/MathSutra_Logo_Dark_300x60.png" alt="MathSutra - Step In to Succeed" className='navbar-img fa-firstdraft'/>
          {/* <i class='fab fa-firstdraft' /> */}
        </NavLink>
       
          <div className='menu-icon' onClick={handleClick}>
          <i className= {click ? 'fa fa-times' : 'fa fa-bars'} />
        </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <NavLink to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </NavLink>
            </li>
             <li className='nav-item'>
             <NavLink to='/aboutus' className='nav-links' onClick={closeMobileMenu}>
           
              Our Story
            </NavLink>
          </li>
          <li className='nav-item'>
             <NavLink to='/contactus' className='nav-links' onClick={closeMobileMenu}>
           
              Contact Us
            </NavLink>
          </li>
          <li className='nav-item'>
          <NavLink to='/gallery' className='nav-links' onClick={closeMobileMenu}>
            {/* <a href='/privacypolicy' className='nav-links' onClick={closeMobileMenu}> */}
              Gallery
            </NavLink>
          </li>
          <li className='nav-item'>
          <NavLink to='/privacypolicy' className='nav-links' onClick={closeMobileMenu}>
            {/* <a href='/privacypolicy' className='nav-links' onClick={closeMobileMenu}> */}
              Privacy Policy
            </NavLink>
          </li>
                   
            </ul>
      </nav>
  
  );
}

export default Navbar;
