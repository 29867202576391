// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavMenu from './components/Navigation/Navbar';
import Privacypolicy from './components/Pages/PrivacyPolicy';
import Footer from './components/Footer/Footer';
import Home from './components/Pages/Home';
import GoTop from './components/Footer/ScrolltoTop';
import SocialMedia from './components/Socialmedia/SocialMedia';
import Products from './components/Pages/Products';
import Aboutus from './components/Aboutus/Aboutusmain';
import Subscription from './components/Subscription/Subscription';
import Gallery from './components/Pages/Gallery';
import bgimage from './ms-bg-image-compressed.png';

function App() {
  return (
    // <div className="App">    
    <>
    <div className="App" style={{ backgroundImage: bgimage,backgroundRepeat:"no-repeat",backgroundSize:"cover" }}>  
      {/* `url('https://mathsutra.s3.ap-south-1.amazonaws.com/ms-bg-image-compressed.png')` */}
       <Router>   
      <NavMenu/>     
      <Routes>
      <Route exact path='/'   element={<Home/>} />
      <Route exact path='/aboutus'   element={<Aboutus/>} />
      <Route exact path='/contactus' element={<Subscription/>}/>
      <Route exact path='/gallery' element={<Gallery/>}/>
      <Route exact path='/privacypolicy'  element={<Privacypolicy/>} />
      </Routes>
      </Router>
      {/* <Home/> */}
      <Footer/>
      {/* <SocialMedia scrollStepInPx="0" delayInMs="0"/>     */}
      {/* WhatsApp icon */}
      <a
        href="https://wa.me/9916897271"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon" aria-hidden="true"></i>
      </a>
       </div>
      </>
  );
}

export default App;
