import React from 'react'
/* import { Icon } from '@iconify/react'
import sendCircle from '@iconify-icons/mdi/send-circle' */
import axios from 'axios';

import './form.css'

class Form extends React.Component {

  state = {
    data:
    {
      name: '',
      phone:'',
      email:'',
      desc:''
    },
    successMessage:'',
    styleValue:'',
    checkMessage:''
  }

 formInputs = [
  { 
    id: 'name', 
    type: 'text', 
    label: 'Your name', 
    placeholder: 'John Doe',   
    datavalue:'name',
   },
  {
    id: 'tel',
    type: 'tel',
    label: 'Phone number',
    placeholder: '+01 234 567 8900',
    datavalue:'phone',
  },
  {
    id: 'email',
    type: 'email',
    label: 'Email address',
    placeholder: 'you@example.com',
    datavalue:'email',
  },
  {
    id: 'message',
    type: 'textarea',
    label: 'Your message',
    placeholder: 'How can we help you?',
    datavalue:'desc',
  },
]

handleChange = name => event =>
 { 
   this.setState({ [name]: event.target.value }); 
   document.getElementById("btnCheckFormData").style="display:none";    
   
 } 


handleSubmit = event => {
  event.preventDefault();
  const headers = {
     // 'Access-Control-Allow-Origin': 'http://localhost:3000',
      'Access-Control-Allow-Origin': process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
      'Access-Control-Allow-Methods':'POST,GET,OPTIONS',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Methods,Access-Control-Allow-Headers,Access-Control-Allow-Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with',
      'X-Requested-With':'*'     
    }

  /* const enquiryData = {      
    name: this.state.name,
    phone: this.state.phone,
    email: this.state.email,
    desc: this.state.desc      
    }; */

    if((this.state.name===undefined) || (this.state.phone===undefined) || (this.state.email===undefined) || (this.state.desc===undefined))
    {
      this.checkMessage = "Please enter all values!!!";
      this.checkFormData();    
    }
    else
    {
    const enquiryConcatData= "<body><h3>Hello,</h3><div>Please find my details below<br/>Name:"+ this.state.name+" <br/> Phone:"+ this.state.phone +" <br/> EMail:"+ this.state.email +"<br/> Details requested for:"+ this.state.desc + ". <br/> Thanks.</div></body></html>";
  //const enquiryConcatData = JSON.stringify("Name:"+ this.state.name + " " + "Phone:"+ this.state.phone +" "+ "EMail:"+this.state.email+" "+ "Details requested for:"+this.state.desc) ;
   //console.log("enquiryConcatData",enquiryConcatData);
  axios.post(`https://vca8cp765h.execute-api.ap-south-1.amazonaws.com/dev/sendbrightenquirymail`, { enquiryConcatData },{
      headers: headers
    })
    .then(res => {
      console.log(res);
      console.log(res.data);
      if(res.status===200)
      {       
        this.setStyle();
      }
    },
    (error) => {
      this.successMessage='There was an error sending mail. Please try again.';
      console.log(error);
    })
    }    
   
}

checkFormData = () =>{
  if(this.checkMessage !=='')  {
    document.getElementById("btnCheckFormData").style="display:block"; 
  }
}

setStyle = () =>{  
  if(this.successMessage!=='')  {
    document.getElementById("btnSuccess").style="display:block"; 
    document.getElementById("btnCheckFormData").style="display:none"; 
  }
}
clearForm = () => {  
  document.getElementById("contactForm").reset();
  document.getElementById("btnSuccess").style="display:none";
  document.getElementById("btnCheckFormData").style="display:none"; 
}

render() {
  return  (
   
  <form id="contactForm" className="form" onSubmit={this.handleSubmit}>
    <h2 className="form-h2">Send us a message</h2>

    {this.formInputs.map(input => (
      <label key={input.label} id={input.id} className="form-label" >
        {input.label}

        {input.type === 'textarea' ? (
          <textarea className="form-textarea" placeholder={input.placeholder} onChange={this.handleChange('desc')} />
        ) : (
          <input
            className="form-input"
            type={input.type}
            placeholder={input.placeholder}
            onChange={this.handleChange(input.datavalue)}
          />
        )}
      </label>
    ))}

    <button className="form-submit" type="submit">
      Send message
    </button>
    <button type="button" className="form-submit" id="btnSuccess" onClick={this.clearForm} style={{display:"none"}}>Thanks for your Mail. We will get back to you shortly.Click to Close.</button>
     <button type="button" className="form-submit" id="btnCheckFormData" style={{display:"none"}}>Please enter all values!!!.</button>
   </form>   
 
 
)
    }
      }
export default Form
