import React from "react";
import './SocialMedia.css';
import { Icon } from '@iconify/react';
import instagramIcon from '@iconify-icons/mdi/instagram';


class SocialMedia extends React.Component {
  state = {
       intervalId: 0,
       thePosition: false
   };

  componentDidMount() {
       this.setState({ thePosition: true });

    
  }
  
  onScrollStep = () => {
      if (window.pageYOffset === 0){
          clearInterval(this.state.intervalId);
      }
      window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop = () => {
        
  }

  renderInstaIcon = () => {
      if (this.state.thePosition){
          return (
              <div className="insta">
              
             
              </div>
          )
      }
  }

  render(){
      return (
          <React.Fragment>
              {this.renderInstaIcon()}
          </React.Fragment>
      )
  }
}

export default SocialMedia;